var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Surveys")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","small":"","to":{
        name: 'cprSurvey',
        params: { id: 0 }
      }}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.surveyKey",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'cprSurvey', params: { id: item.id } }}},[_vm._v(_vm._s(item.surveyKey))])]}},{key:"item.effectiveDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateClient")(item.effectiveDate,"MM/DD/YYYY", _vm.selectedClient))+" ")]}},{key:"item.expirationDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateClient")(item.expirationDate,"MM/DD/YYYY", _vm.selectedClient))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }